import React from "react"

const check = () => {
  if(typeof window !== "undefined") {
    let userAgentInfo = window.navigator.userAgent
    let agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod")
    let flag = true;
    for(let i=0;i<agents.length;i++) {
      if(userAgentInfo.indexOf(agents[i])>0) {
        flag = false;
        break;
      }
    }
    return flag
  }
}

const GetQueryString = (name) => {  
  if(typeof window !== "undefined") {
    var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
     var r = window.location.search.substr(1).match(reg);
     if(r!=null)return  decodeURIComponent(r[2]); return null;
  }
}

const getUrlParms = (name) => {
  if(typeof window !== "undefined") {
    let reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
    let href = window.location.href.substr(window.location.href.indexOf('?'))
    let r = href.substr(1).match(reg);
    if(r!=null)
    return unescape(r[2]);
    return null;
  }
}

const bdstatistics = () => {
  var _hmt = _hmt || [];
  (function() {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?4a27c6f5c06853a320441130ffcda911";
    var s = document.getElementsByTagName("script")[0]; 
    s.parentNode.insertBefore(hm, s);
  })();
  let meta = document.createElement("meta")
  meta.name = 'renderer'
  meta.content = 'webkit'
  let shead = document.getElementsByTagName('head')[0]
  shead.append(meta)
}

const baiduSite = () => {
  (function() {
    let meta = document.createElement("meta")
    meta.name = 'baidu-site-verification'
    meta.content = 'codeva-gfo62omHnA'
    let shead = document.getElementsByTagName('head')[0]
    shead.append(meta)
  })();
}

const mqStatistics = () => {
  // e, j, s
  // (function(a, b, c, d) {
  //     a[d] = a[d] || function() {
  //         (a[d].a = a[d].a || []).push(arguments)
  //     };
  //     var j = b.createElement(c);
  //     var s = b.getElementsByTagName(c)[1];
  //     j.async = true;
  //     j.charset = 'UTF-8';
  //     j.src = 'https://static.meiqia.com/widget/loader.js';
  //     s.parentNode.insertBefore(j, s);
  // })(window, document, 'script', '_MEIQIA');
  // window._MEIQIA('entId', '91e80a639a446e2d9022713004937ec4')
}

// const baseURL = '/migration'
// const apiURL = '/strapi'
const baseURL = 'https://airiot.link/migration'
const apiURL =  "https://airiot.link/strapi"

const techURL = 'https://airiot.tech/resources'


export { bdstatistics, baiduSite, apiURL, mqStatistics, check, GetQueryString, baseURL, getUrlParms, techURL }

