import { Link, useStaticQuery, graphql } from "gatsby"
import React from "react"
import { Row, Media, Col, Card } from 'react-bootstrap'

import '../assets/css/articles.css'

const BannerCard = ({ activeNav, data }) => {
  const obj = {
    '产品': '功能升级',
    '案例': '解决方案',
    '下载': '解决方案'
  }
  return (
    <div className="nav_restructure" 
      onMouseOver={() => {
        document.getElementsByClassName('nav_restructure')[0].style.display = 'block';
        document.getElementsByClassName('nav_restructure')[0].style.opacity = 1
        // setActiveNav('产品')
      }}
      onMouseOut={() => {
        document.getElementsByClassName('nav_restructure')[0].style.display = 'none';
        document.getElementsByClassName('nav_restructure')[0].style.opacity = 0
        // setActiveNav('')
      }}
    >
      <Row>
        <Col className="col restructure_left_col" lg={4}>
          <Articles articlesTag={obj[activeNav]} dataArr={data}/>
        </Col>
        <Col className="col" lg={activeNav == '案例' || activeNav == '下载' ? 8 : 3}>
          <ProductList activeNav={activeNav}/>
        </Col>
        {
          activeNav == '案例' || activeNav == '下载' ? null : <Col style={{ display: 'flex', justifyContent: 'center' }}>
            <EngineImage />
          </Col> 
        }
      </Row>
    </div>
    )
}

const Articles = ({ articlesTag, dataArr }) => {
  const [ data, setData ] = React.useState([])

  React.useEffect(() => {
    if(dataArr?.length && articlesTag) {
      let newJson = dataArr?.length ? dataArr.filter(item => item.title&& item.title.includes(articlesTag) ).splice(0, 3) : []
      setData(newJson)
    }
  }, [ articlesTag, dataArr ])

  return (
    <div className="nav_conter_left">
      <div className="nav_conter_p">
        <div>{articlesTag}</div>
      </div>
      {
        data?.length ? data.map(item => {
          return (
            <Link to={item.articlesTag == '公开课' ? `${item.content}` : `/article/${item.slug}`} target={item.articlesTag == '公开课' ? `_black` : '_self' } className="article-link">
              <Card className="dt-card">
                {/* { item?.image?.url ? <img className="dt-img" src={`${apiURL}${item.image.url}`} alt="" /> : null} */}
                <Card.Body>
                  <div className="dt-nav-title-secrod">{item.title}</div>
                  <div className="dt-nav-title-desc">{item?.description}</div>
                </Card.Body>
              </Card>
            </Link>
          )
        }) : null
      }
    </div>
  )
}

const ProductList = ({ activeNav }) => {
  let obj = {
    '产品': '五大引擎',
    '案例': '案例',
    '下载': '下载'
  }
  const arr = [ 
    { image: require('./../images/数据采集.png'), title: '数据采集与控制引擎', description: '数据采集与控制引擎', url: '/engine?name=数据采集与控制引擎' },
    { image: require('./../images/可视化.png'), title: '可视化组态引擎', description: '可视化组态引擎', url: '/engine?name=可视化组态引擎' },
    { image: require('./../images/数据分析.png'), title: '数据分析引擎', description: '数据分析引擎', url: '/engine?name=数据分析引擎' },
    { image: require('./../images/业务流.png'), title: '业务流引擎', description: '业务流引擎', url: '/engine?name=业务流引擎' },
    { image: require('./../images/二次开发.png'), title: '二次开发引擎', description: '二次开发引擎', url: '/engine?name=二次开发引擎' } ]

  // 只会查回来不到10个
  const applicationScenarios = useStaticQuery(graphql`
  {
    allStrapiApplicationScenario(filter: {homePage: {eq: true}}) {
      nodes {
        bannerTitle
        created_at
        homePage
        id
        introduction
        order
        updated_at
        strapiId
        sort
        published_at
        HomeTabImage {
          childImageSharp {
            fixed(width: 1600, height: 800) {
              base64
            }
          }
        }
      }
    }
  }
`)
  const applicationScenariosData = applicationScenarios?.allStrapiApplicationScenario?.nodes
  const jsonData = applicationScenariosData.filter(item => item?.homePage).sort((a, b) => a.order - b.order).splice(0, 5)
  const data = [ ...jsonData.map(item => {
    const bannerTitle = item.bannerTitle && item.bannerTitle.substring(0, item.bannerTitle.indexOf('场景案例'))
    return { ...item, bannerTitle }
  })]
  // console.log(applicationScenarios, data, '首页安利---------')
   
  // React.useEffect(() => {
  //   //首页案例
  //   fetch(`${apiURL}/application-scenarios?_sort=updated_at:DESC`, {
  //     headers: { 'content-type': 'application/json' },
  //   })
  //     .then((res) => {
  //       if (res.status == 200) {
  //         return res.json()
  //       }
  //     })
  //     .then(json => {
  //       const jsonData = json.filter(item => item?.homePage).sort((a, b) => a.order - b.order).splice(0, 5)
  //       setData([ ...jsonData.map(item => {
  //         const bannerTitle = item.bannerTitle && item.bannerTitle.substring(0, item.bannerTitle.indexOf('场景案例'))
  //         return { ...item, bannerTitle }
  //       }) ])
  //     })
  //     .catch(err => console.log(err))
  // }, [ activeNav ])  

  const getElement = () => {
    if(activeNav == '产品') {
      return <>
        {
          arr.map((item,index) => {
            return <Link to={item?.url}><Media className="product-center-box" key={index}>
            <div className="nav_conter_right_li">
              {
                item?.image ? <img src={item.image} alt={item?.descImg} /> : null
              }
            </div>
            <Media.Body>
              <div className="text_title">{item.title}</div>
            </Media.Body>
          </Media>
          </Link>
          })
        }
        </>
    } else if (activeNav == '案例') {
      return <Row xs={1} lg={3} md={3}>
        {
          data?.length ? data.map(item => {
            console.log(item?.HomeTabImage?.childImageSharp, '====')
            return (
              <Col className="recomments_base" style={{ marginBottom: '1rem' }}>
                <Link to={`/articlesDetail/${item.strapiId}`}>
                  <Card className="base-detail recomments_card">
                    {
                      item?.HomeTabImage?.childImageSharp?.fixed?.base64 ? <img src={item.HomeTabImage.childImageSharp.fixed.base64} alt="" /> : null
                    }
                    {/* {
                      item?.HomeTabImage?.url ? <img src={`${apiURL}${item.HomeTabImage.url}`} alt="" /> : null
                    } */}
                    <Card.Body>
                      <Card.Title>{item.bannerTitle}</Card.Title>
                      <Card.Text>{item.description}</Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            )
          }) : null
        }
        <Col className="recomments_base" style={{ marginBottom: '1rem' }}>
          <Link to="/articles" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%', height: '100%',
            background: 'rgba(232, 244, 255, 0.6)', color: '#007bff' }}>
            <Card className="base-detail" style={{ border: '0px !important', background: 'transparent' }}>
                {'查看更多案例->'}
            </Card>
          </Link>
        </Col>
      </Row>
    } else if (activeNav == '下载') {
      return <div className="down_box_zq"><Row xs={3} lg={3} md={3} className="home_down_row">
        <Col className="home_down_nav">
          <Link to="/airedu?id=v4">
            <div>
              <div>4.0版本</div>
              <div>下载专区</div>
            </div>
          </Link>
        </Col>
        <Col className="home_down_nav">
          <Link to="/airedu?id=v3">
            <div>
              <div>3.0版本</div>
              <div>下载专区</div>
            </div>
          </Link>
        </Col>
        {/* <Col className="home_down_nav">
          <Link to="https://bbs.airiot.link/" target="_black">
            <div>
              <div>BBS</div>
              <div>交流社区</div>
            </div>
          </Link>
        </Col> */}
        <Col className="home_down_nav">
          <Link to="https://r8ja3mlg7i.feishu.cn/share/base/form/shrcnBkykBXVYWsDVaXRNxU3dAf" target="_blank">
            <div>
              <div>申请试用</div>
              <div>授权</div>
            </div>
          </Link>
        </Col>
      </Row>
      </div>
    }
  }

  return (
    <div className="nav_conter_right_ul">
      <div className="nav_conter_right_title">{obj[activeNav]}</div>
      {
        getElement()
      } 
    </div>
  )
}

const EngineImage = () => {
  const image = require('./../images/产品资料下载.png')
  return (
    <div className="nav_public">
      <Link to="ppt">
        <img src={ image } />
        <div style={{ textAlign: 'center' }}>
          <div className="nav_desc">{"产品资料下载->"}</div>
        </div>
      </Link>
    </div>
  )
}

export { BannerCard }